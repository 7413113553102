import { useEffect, useState } from 'react';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';
import AuthenticationRoutes from 'routes/AuthenticationRoutes';
import UnderMaintenanceRoutes from 'routes/UnderMaintenanceRoutes';
import MainRoutes from 'routes/MainRoutes';
import PromotionalDialog from 'views/pages/promotional-signup/PromotionalDialog';

import ThemeCustomization from 'themes';
import { dispatch } from 'store';
import { getDashboard } from 'store/slices/menu';
import { useLocation } from 'react-router-dom';

// auth provider
import { CognitoProvider as AuthProvider } from 'contexts/AWSCognitoHUIContext';

// react-ga
import ReactGA from 'react-ga';
ReactGA.initialize("UA-281615558-1");

// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showPromotionalDialog, setShowPromotionalDialog] = useState(false);
    const shouldShowPromotionalDialog = !localStorage.getItem("user_obj") && !["/pages/promotional-signup"].includes(location.pathname);


    // const
    const authenticationRoutes = AuthenticationRoutes.children
        .filter(route => route.isAuthRequired)
        .map(route => route.path)
    const underMaintenanceRoutes = UnderMaintenanceRoutes.children
        .filter(route => route.isAuthRequired)
        .map(route => route.path)
    const mainRoutes = MainRoutes.children
        .filter(route => route.isAuthRequired)
        .map(route => route.path)
    const authenticationRequiredRouteList = [...authenticationRoutes, ...underMaintenanceRoutes, ...mainRoutes]
    const isAuthProviderRequired = authenticationRequiredRouteList
        .some(routePath => location.pathname.startsWith(routePath));

    useEffect(() => {
        dispatch(getDashboard()).then(() => {
            setLoading(true);
        });
    }, []);
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (shouldShowPromotionalDialog) {
            const timeout = setTimeout(() => {
                setShowPromotionalDialog(true);
            }, 10000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [shouldShowPromotionalDialog]);

    if (!loading) return <Loader />;

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        {isAuthProviderRequired ?
                            <AuthProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </AuthProvider> :
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        }
                        <PromotionalDialog
                            open={showPromotionalDialog}
                            onClose={() => setShowPromotionalDialog(false)}
                        />
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
