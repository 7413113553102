// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDatabase, IconPlus } from '@tabler/icons';

// constant
const icons = {
    IconDatabase,
    IconPlus
};
// ==============================|| WIDGET MENU ITEMS ||============================== //

const indices = {
    id: 'indices',
    // title: <FormattedMessage id="indices" />,
    icon: icons.IconChartArcs,
    type: 'group',
    children: [
        {
            id: 'idx-create-index',
            title: 'Create Index',
            type: 'item',
            url: '/indices/create-index',
            icon: icons.IconPlus
        },
        {
            id: 'view-all-indices',
            title: 'View All Indices',
            type: 'item',
            url: '/indices',
            icon: icons.IconDatabase
        }
    ]
};

export default indices;
