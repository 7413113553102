// material-ui
import { Container, Divider, Stack, Typography, Grid } from '@mui/material';

// assets
import addRecordsUploadJson from 'assets/images/pages/onboarding/addRecords-UploadJson.png';
import addRecordsUploadManually from 'assets/images/pages/onboarding/addRecords-UploadManually.png';
import addRecords from 'assets/images/pages/onboarding/addRecords.png';
import createIndex from 'assets/images/pages/onboarding/createIndex.png';
import simpleLuceneSearch from 'assets/images/pages/onboarding/simpleLuceneSearch.png';

const OnboardingInFiveMin = () => {
    return (
        <>
            <Container sx={{ my: 6 }}>
                <Stack spacing={0.25} alignItems="center">
                    <Typography variant="h2" align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
                        Countdown to Onboarding at HoppySearch: 5 Minutes
                    </Typography>
                </Stack>

            </Container>
            <Divider sx={{ mx: 2, bgcolor: "black", height: 2 }} />
            <Container>
                <Grid item xs={12} container spacing={2} sx={{ my: 5 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">
                            Create Index:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={createIndex} alt="createIndex" style={{ maxWidth: "100%" }} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2} sx={{ mb: 5 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">
                            Add Records:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={addRecords} alt="addRecords" style={{ maxWidth: "100%" }} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2} sx={{ mb: 5 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">
                            1. Upload JSON File:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={addRecordsUploadJson} alt="addRecordsUploadJson" style={{ maxWidth: "100%" }} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2} sx={{ mb: 5 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">
                            2. Add Records Manually:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={addRecordsUploadManually} alt="addRecordsUploadManually" style={{ maxWidth: "100%" }} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2} sx={{ mb: 5 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">
                            Search:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={simpleLuceneSearch} alt="simpleLuceneSearch" style={{ maxWidth: "100%" }} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default OnboardingInFiveMin;
