import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import DataProcessingAgreementInfo from './DataProcessingAgreementInfo';
function DataProcessingAgreement() {
    return (
        <>
            <Container sx={{ my: 6, width: '100%', mb: 10 }}>
                <Stack spacing={0.25} alignItems="center">
                    <Typography
                        align="center"
                        sx={{
                            fontSize: { xs: '1.5rem', sm: '2.6rem' },
                            fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                            mb: 1
                        }}
                    >
                        Data Processing Agreement
                    </Typography>
                </Stack>
            </Container>
            <DataProcessingAgreementInfo />
        </>
    );
}
export default DataProcessingAgreement;
