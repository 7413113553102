import axios from 'axios'

import { getIdToken, getRefreshToken, getAuthTokens, setAuthTokens } from 'store/localStorageService'
import { AUTH_CONFIG } from '../../config'

let tokenUrl = AUTH_CONFIG.tokenUrl;
let clientId = AUTH_CONFIG.clientId
let loginPage = AUTH_CONFIG.loginPage

const API = axios.create({ baseURL: process.env.REACT_APP_API })

API.interceptors.request.use(
    config => {
        const token = getIdToken()
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
          config.headers['Accept'] = 'application/json';
        }
        return config
    },
    error => Promise.reject(error)
)

API.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        const originalRequest = error.config
  
        if (
          error.response.status === 401 &&
          originalRequest.url === tokenUrl
        ) {
            localStorage.removeItem("user_obj")
            window.location = loginPage;
            return Promise.reject(error)
        }
    
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            const refresh_token = getRefreshToken()

            let data = new URLSearchParams();
            data.append("grant_type", "refresh_token")
            data.append("client_id", clientId)
            data.append("refresh_token", refresh_token)    

            return axios
                .post(tokenUrl, data)
                .then(res => {
                    if (res.status >= 200 && res.status < 300) {
                        let new_tokens = res.data

                        setAuthTokens({
                            ...getAuthTokens(),
                            id_token: new_tokens.id_token,
                            access_token: new_tokens.access_token
                        });
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + getIdToken();
                        return axios(originalRequest)
                    } else {
                        console.log(res);
                        alert("some error occured while refreshing auth");
                        localStorage.removeItem("user_obj")
                        window.location = loginPage;
                    }
                }).catch((err) => {
                    console.error(err)
                    localStorage.removeItem("user_obj")
                    window.location = loginPage
                });
        }

        return Promise.reject(error)
    }
  )

export default { API };
