import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import LogRocket from 'logrocket';

// action - state management
import { LOGIN, GET_USER_INFO } from 'store/actions';
import accountReducer from 'store/accountReducer';
import { AUTH_CONFIG } from '../config';
import { userInfoPageCall } from 'store/actions/userActions';
import { useDispatch } from 'react-redux';

let tokenUrl = AUTH_CONFIG.tokenUrl;
let infoUrl = AUTH_CONFIG.infoUrl;

let redirectUrl = AUTH_CONFIG.redirectUrl;
let clientId = AUTH_CONFIG.clientId;
let loginPage = AUTH_CONFIG.loginPage;

let homePage = '/dashboard';

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

let user_str = localStorage.getItem('user_obj');
if (user_str) {
    initialState.isLoggedIn = true;
    initialState.user = JSON.parse(user_str);
}

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const CognitoContext = createContext(null);

export const CognitoProvider = ({ children }) => {
    const use_Dispatch = useDispatch();
    const URL = process.env.REACT_APP_API;

    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const pageLocation = useLocation();

    let retrieveToken = (code) => {
        return new Promise((resolve, reject) => {
            if (code) {
                let data = new URLSearchParams();
                data.append('grant_type', 'authorization_code');
                data.append('client_id', clientId);
                data.append('code', code);
                data.append('redirect_uri', redirectUrl);

                var tokens = {};

                axios
                    .post(tokenUrl, data)
                    .then((response) => {
                        tokens = response.data;
                        let headers = { Authorization: 'Bearer ' + tokens.access_token };
                        return axios.get(infoUrl, { headers });
                    })
                    .then((response) => {
                        let user_info = response.data;
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: {
                                    id: user_info.sub,
                                    email: user_info.email,
                                    name: user_info.username,
                                    auth_tokens: tokens
                                }
                            }
                        });

                        dispatch({
                            type: GET_USER_INFO,
                            payload: {
                                user: {
                                    id: user_info.sub,
                                    email: user_info.email
                                }
                            }
                        });
                        let userData = {
                            email: user_info.email,
                            id: user_info.sub,
                            openPage: true
                        };

                        axios
                            .get(`${URL}/user/${user_info?.sub}`)
                            .then((response) => {
                                const userInfo = response.data;
                                if (!userInfo?.userInfo) {
                                    use_Dispatch(userInfoPageCall(userData));
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        LogRocket.identify(user_info.sub, {
                            name: user_info.username,
                            email: user_info.email
                        });

                        window.pendo.initialize({
                            visitor: {
                                id: user_info.username, // Required if user is logged in, default creates anonymous ID
                                email: user_info.email // Recommended if using Pendo Feedback, or NPS Email
                                // full_name:    // Recommended if using Pendo Feedback
                                // role:         // Optional

                                // You can add any additional visitor level key-values here,
                                // as long as it's not one of the above reserved names.
                            },

                            account: {
                                id: user_info.username // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                                // name:         // Optional
                                // is_paying:    // Recommended if using Pendo Feedback
                                // monthly_value:// Recommended if using Pendo Feedback
                                // planLevel:    // Optional
                                // planPrice:    // Optional
                                // creationDate: // Optional

                                // You can add any additional account level key-values here,
                                // as long as it's not one of the above reserved names.
                            }
                        });

                        resolve();
                    })
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
            }
        });
    };

    useEffect(
        () => {
            let code = searchParams.get('code');

            if (!state.user && !code) {
                localStorage.removeItem('user_obj');
                window.location = loginPage;
            }

            if (!state.user?.auth_tokens?.id_token) {
                if (code) {
                    retrieveToken(code).then(() => {
                        if (pageLocation.pathname === '/auth') navigate(homePage);
                    });
                } else {
                    // If OAuth token is not available and also cognito code is not available, LOGOUT
                    localStorage.removeItem('user_obj');
                    window.location = loginPage;
                }
            } else {
                console.log('id_token avaibale in auth_tokens');

                LogRocket.identify(state.user?.id, {
                    name: state.user?.name,
                    email: state.user?.email
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    return (
        <CognitoContext.Provider
            value={{
                ...state
            }}
        >
            {children}
        </CognitoContext.Provider>
    );
};

CognitoProvider.propTypes = {
    children: PropTypes.node
};

export default CognitoContext;
