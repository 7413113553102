// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = {
    IconDashboard
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const beforeIndices = {
    id: 'before-indices',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default beforeIndices;
