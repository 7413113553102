import React from 'react';
import { Container, Stack, Typography, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import TermsAndConditionsInfo from './termsAndConditionsInfo';

function TermsAndConditions() {
    return (
        <>
            <Container sx={{ my: 6, width: '100%',mb:13 }}>
                <Stack spacing={0.25} alignItems="center">
                    <Typography
                        align="center"
                            sx={{
                                fontSize: { xs: '1.5rem', sm: '2.6rem' },
                                fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                                mb: 1
                            }}
                    >
                        Terms And Conditions
                    </Typography>
                    <Grid container textAlign="center" spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontSize: { sm: '1.0rem' },
                                    fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                                    color: '#7c859b'
                                }}
                            >
                                This Hoppysearch Services Agreement (“Agreement”) governs the use of Hoppysearch provided by Hoppysoft
                                Private Limited, with an office at Suite 1001, Plot No 59, Sector 19, Airoli, Navi Mumbai, Maharashtra,
                                India 400708 (“Hoppysearch”).
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { sm: '1.0rem' },
                                    fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                                    color: '#7c859b'
                                }}
                            >
                                This agreement becomes effective when you sign up for Hoppysearch services (“Effective Date)”.
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
            <TermsAndConditionsInfo />
            {/* <Container >
                <Stack spacing={0.25} alignItems="center">
                    
                </Stack>
            </Container> */}
        </>
    );
}
export default TermsAndConditions;
