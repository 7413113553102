import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DialogActions, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    size='small'
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


const PromotionalDialog = ({ open, onClose }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='xs'>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography variant='h4' style={{ textAlign: 'center', color: '#3f51b5' }}>Get $300 Credit on Signup!</Typography>
            </BootstrapDialogTitle>
            <Divider />
            <DialogContent>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                    At HoppySearch, we are dedicated to helping our customers find the right products and services to help
                    them grow and scale their businesses.
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                    We want to help you build out a proof of concept that works for your business.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    component={Link}
                    to="/pages/promotional-signup"
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: '#3f51b5', color: '#fff' }}
                >
                    Get $300 Promotional Credit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PromotionalDialog;