import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Pricing1 from 'views/pages/pricing/Pricing1';
import OnboardingInFiveMin from 'views/pages/onboarding/OnboardingInFiveMin';
import TermsAndConditions from 'views/pages/termsAndConditions/index';
import DataProcessingAgreement from 'views/pages/DataProcessingAgreement/index';
import PrivacyPolicy from 'views/pages/PrivacyPolicy/index';

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const MaintenanceComingSoon1 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon1')));
const MaintenanceComingSoon2 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon2')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));

// landing & contact-us routing
const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const Searchify = Loadable(lazy(() => import('views/pages/googleLandingPage')));
const PagesContactUS = Loadable(lazy(() => import('views/pages/contact-us')));
const PromotionalSignup = Loadable(lazy(() => import('views/pages/promotional-signup')));
const PagesFaqs = Loadable(lazy(() => import('views/pages/saas-pages/Faqs')));
const PagesPrivacyPolicy = Loadable(lazy(() => import('views/pages/saas-pages/PrivacyPolicy')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/auth',
            element: <Dashboard />,
            isAuthRequired: true
        },

        {
            path: '/pages/error',
            element: <MaintenanceError />,
            isAuthRequired: false
        },
        {
            path: '/pages/coming-soon1',
            element: <MaintenanceComingSoon1 />,
            isAuthRequired: false
        },
        {
            path: '/pages/coming-soon2',
            element: <MaintenanceComingSoon2 />,
            isAuthRequired: false
        },
        {
            path: '/pages/under-construction',
            element: <MaintenanceUnderConstruction />,
            isAuthRequired: false
        },
        {
            path: '/pages/landing',
            element: <PagesLanding />,
            isAuthRequired: false
        },
        {
            path: '/pages/contact-us',
            element: <PagesContactUS />,
            isAuthRequired: false
        },
        {
            path: '/pages/promotional-signup',
            element: <PromotionalSignup />,
            isAuthRequired: false
        },
        {
            path: '/pages/faqs',
            element: <PagesFaqs />,
            isAuthRequired: false
        },
        {
            path: '/pages/privacy-policy',
            element: <PagesPrivacyPolicy />,
            isAuthRequired: false
        },
        {
            path: '/pricing',
            element: <Pricing1 />,
            isAuthRequired: false
        },
        {
            path: '/terms',
            element: <TermsAndConditions />,
            isAuthRequired: false
        },
        {
            path: '/data-processing-agreement',
            element: <DataProcessingAgreement />,
            isAuthRequired: false
        },
        {
            path: '/privacy',
            element: <PrivacyPolicy />,
            isAuthRequired: false
        },
        {
            path: '/onboarding',
            element: <OnboardingInFiveMin />,
            isAuthRequired: false
        },
        {
            path: '/searchify',
            element: <Searchify />,
            isAuthRequired: false
        }
    ]
};

export default AuthenticationRoutes;
