// project imports
import services from 'utils/mockAdapter';

// user list
const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: []
};

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/dashboard').reply(200, { dashboard });
