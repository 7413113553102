export const pageInfo = [
    {
        title: 'Types of Data collected',
        info: [
            {
                description:
                    'Among the types of Personal Data that Hoppysearch Cloud collects, by itself or through third parties, there are: Cookies; Usage Data; various types of Data; first name; last name; phone number; address; email address; username; company name; country; city; screenshots; Data communicated while using the service; answers to questions; clicks; keypress events; motion sensor events; mouse movements; scroll position; touch events.'
            },
            {
                description:
                    'Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.'
            },
            {
                description:
                    'Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using Hoppysearch Cloud.'
            },
            {
                description:
                    'Unless specified otherwise, all Data requested by Hoppysearch Cloud is mandatory and failure to provide this Data may make it impossible for Hoppysearch Cloud to provide its services. In cases where Hoppysearch Cloud specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.'
            },
            {
                description:
                    'Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner. Any use of Cookies – or of other tracking tools — by Hoppysearch Cloud or by the owners of third-party services used by Hoppysearch Cloud serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.'
            },
            {
                description:
                    'Users are responsible for any third-party Personal Data obtained, published or shared through Hoppysearch Cloud and confirm that they have the third party’s consent to provide the Data to the Owner.'
            }
        ]
    },
    {
        title: 'Legal basis of processing',
        info: [
            {
                description: 'The Owner may process Personal Data relating to Users if one of the following applies:'
            },
            {
                description:
                    '• Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;'
            },
            {
                description:
                    '• Provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;'
            },
            {
                description: '• Processing is necessary for compliance with a legal obligation to which the Owner is subject;'
            },
            {
                description:
                    '• Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;'
            },
            {
                description:
                    '• Processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.'
            },
            {
                description:
                    'In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.'
            }
        ]
    },
    {
        title: 'Place',
        info: [
            {
                description:
                    'The Data is processed at the Owner’s operating offices and in any other places where the parties involved in the processing are located.'
            },
            {
                description:
                    'Depending on the User’s location, data transfers may involve transferring the User’s Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.'
            },
            {
                description:
                    'If broader protection standards are applicable, Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.'
            },
            {
                description:
                    'If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.'
            }
        ]
    },
    {
        title: 'Retention time:',
        info: [
            {
                description:
                    'Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.'
            },
            {
                description: 'Therefore:'
            },
            {
                description:
                    '• Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.'
            },
            {
                description:
                    '• Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.'
            },
            {
                description:
                    'The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.'
            },
            {
                description:
                    'Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.'
            }
        ]
    },
    {
        title: 'The purposes of processing',
        info: [
            {
                description:
                    'The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Analytics, Hosting and backend infrastructure, Handling payments, Registration and authentication, Data transfer outside the EU, Displaying content from external platforms, Managing support and contact requests, Infrastructure monitoring, Handling productivity related activity , Productivity, Tag Management, Contacting the User and SPAM protection.'
            },
            {
                description:
                    'For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.'
            }
        ]
    },
    {
        title: 'Detailed information on the processing of Personal Data',
        info: [
            {
                description: 'Personal Data is collected for the following purposes and using the following services:'
            }
        ]
    },
    {
        title: 'Analytics',
        info: [
            {
                description:
                    'The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.'
            }
        ]
    },
    {
        title: 'Google Analytics',
        info: [
            {
                description:
                    'Google Analytics is a web analysis service provided by Google LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing, (“Google”). Google utilizes the Data collected to track and examine the use of Hoppysearch Cloud, to prepare reports on its activities and share them with other Google services.'
            },
            {
                description: 'Google may use the Data collected to contextualize and personalize the ads of its own advertising network.'
            },
            {
                description: 'Personal Data processed: Cookies; Usage Data.'
            },
            {
                description: 'Place of processing: United States – Privacy Policy – Opt Out; Ireland – Privacy Policy – Opt Out.'
            },
            {
                description: 'Category of personal information collected according to the CCPA: internet information.'
            },
            {
                description: 'This processing constitutes:'
            },
            {
                description: '• a sale according to the VCDPA'
            }
        ]
    },
    {
        title: 'Contacting the User'
    },
    {
        title: 'Contact form (Hoppysearch Cloud)',
        info: [
            {
                description:
                    'By filling in the contact form with their Data, the User authorizes Hoppysearch Cloud to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.'
            },
            {
                description: 'Personal Data processed: city; country; email address; first name; last name.'
            },
            {
                description: 'Category of personal information collected according to the CCPA: identifiers.'
            },
            {
                description: 'This processing constitutes:'
            },
            {
                description: '• a sale according to the VCDPA'
            }
        ]
    },
    {
        title: 'Data transfer outside the EU',
        info: [
            {
                description:
                    'The Owner is allowed to transfer Personal Data collected within the EU to third countries (i.e. any country not part of the EU) only pursuant to a specific legal basis. Any such Data transfer is based on one of the legal bases described below. Users can inquire with the Owner to learn which legal basis applies to which specific service.'
            }
        ]
    },
    {
        title: 'Data transfer abroad based on standard contractual clauses (Hoppysearch Cloud)',
        info: [
            {
                description:
                    'If this is the legal basis, the transfer of Personal Data from the EU to third countries is carried out by the Owner according to “standard contractual clauses” provided by the European Commission. This means that Data recipients have committed to process Personal Data in compliance with the data protection standards set forth by EU data protection legislation. For further information, Users are requested to contact the Owner through the contact details provided in the present document.'
            },
            {
                description:
                    'Personal Data processed: various types of Data. Category of personal information collected according to the CCPA: internet information. Handling payments'
            },
            {
                description:
                    'Unless otherwise specified, Hoppysearch Cloud processes any payments by credit card, bank transfer or other means via external payment service providers. In general and unless where otherwise stated, Users are requested to provide their payment details and personal information directly to such payment service providers. Hoppysearch Cloud isn’t involved in the collection and processing of such information: instead, it will only receive a notification by the relevant payment service provider as to whether payment has been successfully completed.'
            }
        ]
    },
    {
        title: 'Razorpay(Razorpay Software Private Limited)',
        info: [
            {
                description: 'Razorpay is a payment service provided by Razorpay Software Private Limited.'
            },
            {
                description: 'Personal Data processed: various types of Data as specified in the privacy policy of the service.'
            },
            {
                description: 'Place of processing: United States – Privacy Policy.'
            },
            {
                description: 'Category of personal information collected according to the CCPA: internet information.'
            }
        ]
    },
    {
        title: 'Handling productivity related activity',
        info: [
            {
                description:
                    'This type of service helps the Owner to manage tasks, collaboration and, in general, activities related to productivity. In using this type of service, Data of Users will be processed and may be retained, depending on the purpose of the activity in question. These services may be integrated with a wide range of third-party services disclosed within this privacy policy to enable the Owner to import or export Data needed for the relative activity.'
            }
        ]
    },
    {
        title: 'Google Workspace:',
        info: [
            {
                description:
                    'Google Workspace is an integrated suite of cloud-based productivity, collaboration and storage services provided by Google LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing. Gmail or other G Suite services are not scanned by Google for advertising purposes. In addition, Google does not collect or use data inside these services for advertising purposes in any other way.'
            },
            {
                description:
                    'Personal Data processed: address; city; company name; country; Data communicated while using the service; email address; first name; last name; phone number; screenshots; username.'
            },
            {
                description: 'Place of processing: United States – Privacy Policy; Ireland – Privacy Policy.'
            },
            {
                description:
                    'Category of personal information collected according to the CCPA: identifiers; commercial information; internet information.'
            },
            {
                description: 'This processing constitutes:'
            },
            {
                description: '• a sale according to the CCPA'
            }
        ]
    },
    {
        title: 'Hosting and backend infrastructure',
        info: [
            {
                description:
                    'This type of service has the purpose of hosting Data and files that enable Hoppysearch Cloud to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of Hoppysearch Cloud.'
            },
            {
                description:
                    'Some services among those listed below, if any, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.'
            }
        ]
    },
    {
        title: 'Amazon Web Services (AWS) (Amazon Web Services, Inc.)',
        info: [
            {
                description: 'Amazon Web Services (AWS) is a hosting and backend service provided by Amazon Web Services, Inc.'
            },
            {
                description: 'Personal Data processed: various types of Data as specified in the privacy policy of the service.'
            },
            {
                description:
                    'Place of processing: United States – Privacy Policy; Canada – Privacy Policy; Brazil – Privacy Policy; Ireland – Privacy Policy; United Kingdom – Privacy Policy; Germany – Privacy Policy; India – Privacy Policy; Australia – Privacy Policy; Singapore – Privacy Policy; Bahrain – Privacy Policy; South Africa – Privacy Policy.'
            },
            {
                description: 'Category of personal information collected according to the CCPA: internet information.'
            }
        ]
    },
    {
        title: 'Managing support and contact requests',
        info: [
            {
                description:
                    'This type of service allows Hoppysearch Cloud to manage support and contact requests received via email or by other means, such as the contact form. The Personal Data processed depend on the information provided by the User in the messages and the means used for communication (e.g. email address).'
            }
        ]
    },
    {
        title: 'Zoho (Zoho Corporation Pvt. Ltd)',
        info: [
            {
                description: 'Zoho is a support and contact request management service provided by Zoho Corporation Pvt. Ltd.'
            },
            {
                description: 'Personal Data processed: various types of Data as specified in the privacy policy of the service.'
            },
            {
                description: 'Place of processing: Global – Privacy Policy'
            },
            {
                description: 'Category of personal information collected according to the CCPA: internet information.'
            },
            {
                description: 'This processing constitutes:'
            },
            {
                description: '• a sale according to the VCDPA'
            }
        ]
    },
    {
        title: 'Registration and authentication',
        info: [
            {
                description:
                    'By registering or authenticating, Users allow Hoppysearch Cloud to identify them and give them access to dedicated services. Depending on what is described below, third parties may provide registration and authentication services. In this case, Hoppysearch Cloud will be able to access some Data, stored by these third-party services, for registration or identification purposes. Some of the services listed below may also collect Personal Data for targeting and profiling purposes; to find out more, please refer to the description of each service.'
            }
        ]
    },
    {
        title: 'Google OAuth (G Inc.)',
        info: [
            {
                description: 'Google OAuth is a registration and authentication service provided by Google.'
            },
            {
                description: 'Personal Data processed: various types of Data as specified in the privacy policy of the service.'
            },
            {
                description: 'Place of processing: United States – Privacy Policy.'
            },
            {
                description: 'Category of personal information collected according to the CCPA: internet information.'
            },
            {
                description: 'This processing constitutes:'
            },
            {
                description: '• a sale according to the VCDPA'
            }
        ]
    },
    {
        title: 'SPAM protection',
        info: [
            {
                description:
                    'This type of service analyzes the traffic of Hoppysearch Cloud, potentially containing Users’ Personal Data, with the purpose of filtering it from parts of traffic, messages and content that are recognized as SPAM.'
            }
        ]
    },
    {
        title: 'Google reCAPTCHA (Google LLC)',
        info: [
            {
                description:
                    'Google reCAPTCHA is a SPAM protection service provided by Google LLC. The use of reCAPTCHA is subject to the Google privacy policy and terms of use.'
            },
            {
                description:
                    'Personal Data processed: answers to questions; clicks; keypress events; motion sensor events; mouse movements; scroll position; touch events; Trackers; Usage Data.'
            },
            {
                description: 'Place of processing: United States – Privacy Policy.'
            },
            {
                description: 'Category of personal information collected according to the CCPA: internet information; inferred information.'
            }
        ]
    },
    {
        title: 'Tag Management',
        info: [
            {
                description:
                    'This type of service helps the Owner to manage the tags or scripts needed on Hoppysearch Cloud in a centralized fashion. This results in the Users’ Data flowing through these services, potentially resulting in the retention of this Data.'
            }
        ]
    },
    {
        title: 'Google Tag Manager',
        info: [
            {
                description:
                    'Google Tag Manager is a tag management service provided by Google LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing.'
            },
            {
                description: 'Personal Data processed: Usage Data.'
            },
            {
                description: 'Place of processing: United States – Privacy Policy; Ireland – Privacy Policy.'
            },
            {
                description: 'Category of personal information collected according to the CCPA: internet information.                      '
            }
        ]
    },
    {
        title: 'Further information about the processing of Personal Data'
    },
    {
        title: 'Selling goods and services online',
        info: [
            {
                description:
                    'The Personal Data collected are used to provide the User with services or to sell goods, including payment and possible delivery. The Personal Data collected to complete the payment may include the credit card, the bank account used for the transfer, or any other means of payment envisaged. The kind of Data collected by Hoppysearch Cloud depends on the payment system used.'
            }
        ]
    },
    {
        title: 'The rights of Users',
        info: [
            {
                description: 'Users may exercise certain rights regarding their Data processed by the Owner.'
            },
            {
                description:
                    'Users entitled to broader protection standards may exercise any of the rights described below. In all other cases, Users may inquire with the Owner to find out which rights apply to them.'
            },
            {
                description: 'In particular, Users have the right to do the following:'
            },
            {
                description:
                    '• Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.'
            },
            {
                description:
                    '• Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.'
            },
            {
                description:
                    '• Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.'
            },
            {
                description:
                    '• Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.'
            },
            {
                description:
                    '• Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.'
            },
            {
                description:
                    '• Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.'
            },
            {
                description:
                    '• Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User’s consent, on a contract which the User is part of or on pre-contractual obligations thereof.'
            },
            {
                description: '• Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.'
            }
        ]
    },
    {
        title: 'Details about the right to object to processing',
        info: [
            {
                description:
                    'Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection. Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document. How to exercise these rights'
            },
            {
                description:
                    'Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.'
            }
        ]
    },
    {
        title: 'Applicability of broader protection standards',
        info: [
            {
                description:
                    'While most provisions of this document concern all Users, some provisions expressly only apply if the processing of Personal Data is subject to broader protection standards.'
            },
            {
                description: 'Such broader protection standards apply when the processing:'
            },
            {
                description: '• is performed by an Owner based within the EU;'
            },
            {
                description:
                    '• concerns the Personal Data of Users who are in the EU and is related to the offering of paid or unpaid goods or services, to such Users;'
            },
            {
                description:
                    '• concerns the Personal Data of Users who are in the EU and allows the Owner to monitor such Users’ behavior taking place in the EU.'
            }
        ]
    },
    {
        title: 'Cookie Policy',
        info: [
            {
                description: 'Hoppysearch Cloud uses Trackers. To learn more, the User may consult the Cookie Policy.'
            }
        ]
    },
    {
        title: 'Additional information about Data collection and processing'
    },
    {
        title: 'Legal action',
        info: [
            {
                description:
                    'The User’s Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of Hoppysearch Cloud or the related Services. The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities. Additional information about User’s Personal Data'
            },
            {
                description:
                    'In addition to the information contained in this privacy policy, Hoppysearch Cloud may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.'
            }
        ]
    },
    {
        title: 'System logs and maintenance',
        info: [
            {
                description:
                    'For operation and maintenance purposes, Hoppysearch Cloud and any third-party services may collect files that record interaction with Hoppysearch Cloud (System logs) or use other Personal Data (such as the IP Address) for this purpose.'
            }
        ]
    },
    {
        title: 'Information not contained in this policy',
        info: [
            {
                description:
                    'More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.'
            }
        ]
    },
    {
        title: 'How “Do Not Track” requests are handled',
        info: [
            {
                description:
                    'Hoppysearch Cloud does not support “Do Not Track” requests. To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.'
            }
        ]
    },
    {
        title: 'Changes to this privacy policy',
        info: [
            {
                description:
                    'The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within Hoppysearch Cloud and/or – as far as technically and legally feasible – sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.'
            },
            {
                description:
                    'Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.'
            }
        ]
    },
    {
        title: 'Information for Californian consumers',
        info: [
            {
                description:
                    'This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the business running Hoppysearch Cloud and, if the case may be, its parent, subsidiaries and affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).'
            },
            {
                description:
                    'The provisions contained in this section apply to all Users (Users are referred to below, simply as “you”, “your”, “yours”), who are consumers residing in the state of California, United States of America, according to the “California Consumer Privacy Act of 2018” (the “CCPA”), as updated by the “California Privacy Rights Act” (the “CPRA”) and subsequent regulations. For such consumers, these provisions supersede any other possibly divergent or conflicting provisions contained in the privacy policy.'
            },
            {
                description:
                    'This part of the document uses the terms “personal information” (and “sensitive personal information”) as defined in the California Consumer Privacy Act (CCPA).'
            }
        ]
    },
    {
        title: 'Notice at collection',
        info: [
            {
                description:
                    'Categories of personal information collected, used, sold, or shared In this section we summarize the categories of personal information that we’ve collected, used, sold, or shared and the purposes thereof. You can read about these activities in detail in the section titled “Detailed information on the processing of Personal Data” within this document.'
            }
        ]
    },
    {
        title: 'Information we collect: the categories of personal information we collect',
        info: [
            {
                description:
                    'We have collected the following categories of personal information about you: identifiers, commercial information, internet information and inferred information.'
            },
            {
                description: 'We have collected the following categories of sensitive personal information: username'
            },
            {
                description: 'We will not collect additional categories of personal information without notifying you.'
            }
        ]
    },
    {
        title: 'Your right to limit the use or disclosure of your sensitive personal information and how you can exercise it',
        info: [
            {
                description:
                    'You have the right to request that we limit the use or disclosure of your sensitive personal information to only that which is necessary to perform the services or provide the goods, as is reasonably expected by an average consumer.'
            },
            {
                description:
                    'We can also use your sensitive personal information to perform specific purposes set forth by the law (such as, including but not limited to, helping to ensure security and integrity; undertaking activities to verify or maintain the quality or safety of our service) and as authorized by the relevant regulations.'
            },
            {
                description:
                    'Outside of the aforementioned specific purposes, you have the right to freely request, at any time, that we do not use or disclose your sensitive personal information. This means that whenever you ask us to stop using your sensitive personal information, we will abide by your request and we will instruct our service providers and contractors to do the same.'
            },
            {
                description:
                    'To fully exercise your right to limit the use or disclosure of your sensitive personal information you can contact us at any time, using the contact details provided in this document.'
            },
            {
                description: 'For a simplified opt-out method you can also use the privacy choices link provided on Hoppysearch Cloud.'
            },
            {
                description:
                    'We use any personal information collected from you in connection with the submission of your request solely for the purposes of complying with the request.'
            },
            {
                description:
                    'Once you have exercised this right, we are required to wait at least 12 months before asking whether you have changed your mind.'
            }
        ]
    },
    {
        title: 'What are the purposes for which we use your personal information?',
        info: [
            {
                description:
                    'We may use your personal information to allow the operational functioning of Hoppysearch Cloud and features thereof (“business purposes”). In such cases, your personal information will be processed in a fashion necessary and proportionate to the business purpose for which it was collected, and strictly within the limits of compatible operational purposes.'
            },
            {
                description:
                    'We may also use your personal information for other reasons such as for commercial purposes (as indicated within the section “Detailed information on the processing of Personal Data” within this document), as well as for complying with the law and defending our rights before the competent authorities where our rights and interests are threatened or we suffer an actual damage.'
            },
            {
                description:
                    'We won’t process your information for unexpected purposes, or for purposes incompatible with the purposes originally disclosed, without your consent.'
            }
        ]
    },
    {
        title: 'How long do we keep your personal information?',
        info: [
            {
                description:
                    'Unless stated otherwise inside the “Detailed information on the processing of Personal Data” section, we will not retain your personal information for longer than is reasonably necessary for the purpose(s) they have been collected for.'
            }
        ]
    },
    {
        title: 'How we collect information: what are the sources of the personal information we collect?',
        info: [
            {
                description:
                    'We collect the above-mentioned categories of personal information, either directly or indirectly, from you when you use Hoppysearch Cloud.'
            },
            {
                description:
                    'For example, you directly provide your personal information when you submit requests via any forms on Hoppysearch Cloud. You also provide personal information indirectly when you navigate Hoppysearch Cloud, as personal information about you is automatically observed and collected.'
            },
            {
                description:
                    'Finally, we may collect your personal information from third parties that work with us in connection with the Service or with the functioning of this Application and features thereof.'
            }
        ]
    },
    {
        title: 'How we use the information we collect: disclosing of your personal information with third parties for a business purpose',
        info: [
            {
                description:
                    'For our purposes, the word “third party” means a person who is not any of the following: a service provider or a contractor, as defined by the CCPA.'
            },
            {
                description:
                    'We disclose your personal information with the third parties listed in detail in the section titled “Detailed information on the processing of Personal Data” within this document. These third parties are grouped and categorized in accordance with the different purposes of processing.'
            }
        ]
    },
    {
        title: 'Sale or sharing of your personal information',
        info: [
            {
                description:
                    'For our purposes, the word “sale” means any “selling, renting, releasing, disclosing, disseminating, making available, transferring or otherwise communicating orally, in writing, or by electronic means, a consumer’s personal information by the business to a third party, for monetary or other valuable consideration” , as defined by the CCPA.'
            },
            {
                description:
                    'This means that, for example, a sale can happen whenever an application runs ads, or makes statistical analyses on the traffic or views, or simply because it uses tools such as social network plugins and the like.'
            },
            {
                description:
                    'For our purposes, the word “sharing” means any “sharing, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer’s personal information by the business to a third party for cross-context behavioral advertising, whether or not for monetary or other valuable consideration, including transactions between a business and a third party for cross-context behavioral advertising for the benefit of a business in which no money is exchanged”, as defined by the CCPA. Please note that the exchange of personal information with a service provider pursuant to a written contract that meets the requirements set by the CCPA, does not constitute a sale or sharing of your personal information.'
            }
        ]
    },
    {
        title: 'Your right to opt out of the sale or sharing of your personal information and how you can exercise it',
        info: [
            {
                description:
                    'We sell or share your personal information with the third parties listed in detail in the section titled “Detailed information on the processing of Personal Data” within this document. These third parties are grouped and categorized in accordance with the different purposes of processing.'
            },
            {
                description:
                    'You have the right to opt out of the sale or sharing of your personal information. This means that whenever you request us to stop selling or sharing your personal information, we will abide by your request.'
            },
            {
                description:
                    'Such requests can be made freely, at any time, without submitting any verifiable request. To fully exercise your right to opt out, you can contact us at any time using the contact details provided in this document. For a simplified opt-out method you can also use the privacy choices link provided on Hoppysearch Cloud.'
            },
            {
                description:
                    'If you want to submit requests to opt out of the sale or sharing of personal information via a user-enabled global privacy control, like the Global Privacy Control (“GPC”), you are free to do so and we will abide by such request. The GPC consists of a setting or extension in the browser or mobile device and acts as a mechanism that websites can use to indicate they support the GPC signal. If you want to use GPC, you can download and enable it via a participating browser or browser extension. More information about downloading GPC is available here.'
            },
            {
                description:
                    'We use any personal information collected from you in connection with the submission of your opt-out request solely for the purposes of complying with the opt-out request.'
            },
            {
                description:
                    'Once you have opted out, we are required to wait at least 12 months before asking whether you have changed your mind.'
            }
        ]
    },
    {
        title: 'What are the purposes for which we use your personal information?',
        info: [
            {
                description:
                    'We may use your personal information to allow the operational functioning of Hoppysearch Cloud and features thereof (“business purposes”). In such cases, your personal information will be processed in a fashion necessary and proportionate to the business purpose for which it was collected, and strictly within the limits of compatible operational purposes.'
            },
            {
                description:
                    'We may also use your personal information for other reasons such as for commercial purposes (as indicated within the section “Detailed information on the processing of Personal Data” within this document), as well as for complying with the law and defending our rights before the competent authorities where our rights and interests are threatened or we suffer an actual damage.'
            },
            {
                description:
                    'We won’t process your information for unexpected purposes, or for purposes incompatible with the purposes originally disclosed, without your consent.'
            }
        ]
    },
    {
        title: 'How long do we keep your personal information?',
        info: [
            {
                description:
                    'Unless stated otherwise inside the “Detailed information on the processing of Personal Data” section, we will not retain your personal information for longer than is reasonably necessary for the purpose(s) they have been collected for.'
            }
        ]
    },
    {
        title: 'Your privacy rights under the California Consumer Privacy Act and how to exercise them'
    },
    {
        title: 'The right to access personal information: the right to know and to portability',
        info: [
            {
                description: 'You have the right to request that we disclose to you:'
            },
            {
                description: `• the categories of personal information that we collect about you;`
            },
            {
                description: `• the sources from which the personal information is collected;`
            },
            {
                description: `• the purposes for which we use your information;`
            },
            {
                description: `• to whom we disclose such information;`
            },
            {
                description: `• the specific pieces of personal information we have collected about you.`
            },
            {
                description: `You also have the right to know what personal information is sold or shared and to whom. In particular, you have the right to request two separate lists from us where we disclose:`
            },
            {
                description: `• the categories of personal information that we sold or shared about you and the categories of third parties to whom the personal information was sold or shared;`
            },
            {
                description: `The disclosure described above will be limited to the personal information collected or used over the past 12 months.`
            },
            {
                description: `If we deliver our response electronically, the information enclosed will be “portable”, i.e. delivered in an easily usable format to enable you to transmit the information to another entity without hindrance — provided that this is technically feasible.`
            }
        ]
    },
    {
        title: 'The right to request the deletion of your personal information',
        info: [
            {
                description:
                    'You have the right to request that we delete any of your personal information, subject to exceptions set forth by the law (such as, including but not limited to, where the information is used to identify and repair errors on Hoppysearch Cloud, to detect security incidents and protect against fraudulent or illegal activities, to exercise certain rights etc.).'
            },
            {
                description:
                    'If no legal exception applies, as a result of exercising your right, we will delete your personal information and notify any of our service providers and all third parties to whom we have sold or shared the personal information to do so — provided that this is technically feasible and doesn’t involve disproportionate effort.'
            }
        ]
    },
    {
        title: 'The right to correct inaccurate personal information',
        info: [
            {
                description:
                    'You have the right to request that we correct any inaccurate personal information we maintain about you, taking into account the nature of the personal information and the purposes of the processing of the personal information.'
            }
        ]
    },
    {
        title: 'The right to opt out of sale or sharing of personal information and to limit the use of your sensitive personal information',
        info: [
            {
                description:
                    'You have the right to opt out of the sale or sharing of your personal information. You also have the right to request that we limit our use or disclosure of your sensitive personal information.'
            }
        ]
    },
    {
        title: 'The right of no retaliation following opt-out or exercise of other rights (the right to non-discrimination)',
        info: [
            {
                description:
                    'We will not discriminate against you for exercising your rights under the CCPA. This means that we will not discriminate against you, including, but not limited to, by denying goods or services, charging you a different price, or providing a different level or quality of goods or services just because you exercised your consumer privacy rights.'
            },
            {
                description:
                    'However, if you refuse to provide your personal information to us or ask us to delete or stop selling your personal information, and that personal information or sale is necessary for us to provide you with goods or services, we may not be able to complete that transaction.'
            },
            {
                description:
                    'To the extent permitted by the law, we may offer you promotions, discounts, and other deals in exchange for collecting, keeping, or selling your personal information, provided that the financial incentive offered is reasonably related to the value of your personal information.'
            }
        ]
    },
    {
        title: 'How to exercise your rights',
        info: [
            {
                description:
                    'To exercise the rights described above, you need to submit your verifiable request to us by contacting us via the details provided in this document.'
            },
            {
                description:
                    'For us to respond to your request, it’s necessary that we know who you are. Therefore, you can only exercise the above rights by making a verifiable request which must:'
            },
            {
                description:
                    '• provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative;'
            },
            {
                description:
                    '• describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.'
            },
            {
                description:
                    'We will not respond to any request if we are unable to verify your identity and therefore confirm the personal information in our possession actually relates to you.'
            },
            {
                description:
                    'Making a verifiable consumer request does not require you to create an account with us. We will use any personal information collected from you in connection with the verification of your request solely for the purposes of verification and shall not further disclose the personal information, retain it longer than necessary for purposes of verification, or use it for unrelated purposes.'
            },
            {
                description:
                    'If you cannot personally submit a verifiable request, you can authorize a person registered with the California Secretary of State to act on your behalf.'
            },
            {
                description:
                    'If you are an adult, you can make a verifiable request on behalf of a minor under your parental authority.'
            },
            {
                description:
                    'You can submit a maximum number of 2 requests over a period of 12 months.'
            }
        ]
    },
    {
        title: 'How and when we are expected to handle your request',
        info: [
            {
                description:
                    'We will confirm receipt of your verifiable request within 10 days and provide information about how we will process your request.'
            },
            {
                description:
                    'We will respond to your request within 45 days of its receipt. Should we need more time, we will explain to you the reasons why, and how much more time we need. In this regard, please note that we may take up to 90 days to fulfill your request.'
            },
            {
                description:
                    'Our disclosure(s) will cover the preceding 12-month period. Only with regard to personal information collected on or after January 1, 2022, you have the right to request that we disclose information beyond the 12-month period, and we will provide them to you unless doing so proves impossible or would involve a disproportionate effort.'
            },
            {
                description:
                    'Should we deny your request, we will explain you the reasons behind our denial.'
            },
            {
                description:
                    'We do not charge a fee to process or respond to your verifiable request unless such request is manifestly unfounded or excessive. In such cases, we may charge a reasonable fee, or refuse to act on the request. In either case, we will communicate our choices and explain the reasons behind it.'
            }
        ]
    },{
        title: 'Information for Virginia consumers',
        info: [
            {
                description:
                    'This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the controller running this Application and, if the case may be, its parent, subsidiaries and affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).'
            },
            {
                description:
                    'The provisions contained in this section apply to all Users (Users are referred to below, simply as “you”, “your”, “yours”), who are consumers residing in the Commonwealth of Virginia, according to the “Virginia Consumer Data Protection Act” (the “VCDPA”), and, for such consumers, these provisions supersede any other possibly divergent or conflicting provisions contained in the privacy policy.'
            },
            {
                description:
                    'This part of the document uses the term “personal data” as defined in the VCDPA.'
            }
        ]
    },{
        title: 'Categories of personal data processed',
        info: [
            {
                description:
                    'In this section, we summarize the categories of personal data that we’ve processed and the purposes thereof.'
            }
        ]
    },{
        title: 'You can read about these activities in detail in the section titled “Detailed information on the processing of Persona Data” within this document.',
       
    },{
        title: 'Categories of personal data we collect',
        info: [
            {
                description:
                    'We have collected the following categories of personal data: identifiers, commercial information, internet information, sensorial information and inferred information'
            },
            {
                description:
                    'We do not collect sensitive data.'
            },
            {
                description:
                    'We will not collect additional categories of personal data without notifying you.'
            }
        ]
    },{
        title: 'Why we process your personal data',
        info: [
            {
                description:
                    'To find out why we process your personal data, you can read the sections titled “Detailed information on the processing of Personal Data” and “The purposes of processing” within this document.'
            },
            {
                description:
                    'We won’t process your information for unexpected purposes, or for purposes incompatible with the purposes originally disclosed, without your consent. You can freely give, deny, or withdraw such consent at any time using the contact details provided in this document.'
            }
        ]
    },{
        title: 'How we use the data we collect: sharing of your personal data with third parties',
        info: [
            {
                description:
                    'We share your personal data with the third parties listed in detail in the section titled “Detailed information on the processing of Personal Data” within this document. These third parties are grouped and categorized in accordance with the different purposes of processing. For our purposes, the word “third party” means “a natural or legal person, public authority, agency, or body other than the consumer, controller, processor, or an affiliate of the processor or the controller” as defined by the VCDPA.'
            }
        ]
    },{
        title: 'Sale of your personal data',
        info: [
            {
                description:
                    'For our purposes, the word “sale” means any “exchange of personal data for monetary consideration by us to a third party“ as defined by the VCDPA. Please note that according to the VCDPA, the disclosure of personal data to a processor that processes personal data on behalf of a controller does not constitute a sale. As specified in the “Detailed information on the processing of Personal Data” section of this document, our use of your personal information may be considered a sale under VCDPA.'
            }
        ]
    },{
        title: 'Your right to opt out of the sale of your personal data and how you can exercise it',
        info: [
            {
                description:
                    'You have the right to opt out of the sale of your personal data. This means that whenever you request us to stop selling your data, we will abide by your request. To fully exercise your right to opt out you can contact us at any time using the contact details provided in this document.'
            },{
                description:
                    'We use any personal data collected from you in connection with the submission of your opt-out request solely for the purpose of complying with the request.'
            }
        ]
    },{
        title: 'Processing of your personal data for targeted advertising',
        info: [
            {
                description:
                    'For our purposes, the word “targeted advertising” means “displaying advertisements to you where the advertisement is selected based on personal data obtained from your activities over time and across nonaffiliated websites or online applications to predict your preferences or interests” as defined by the VCDPA.'
            },
            {
                description:
                    'To find out more details on the processing of your personal data for targeted advertising purposes, you can read the section titled “Detailed information on the processing of Personal Data” within this document.'
            }
        ]
    },{
        title: 'Your right to opt out of the processing of your personal data for targeted advertising and how you can exercise it',
        info: [
            {
                description:
                    'You have the right to opt out of the processing of your personal data for targeted advertising. This means that whenever you ask us to stop processing your data for targeted advertising, we will abide by your request. To fully exercise your right to opt out you can contact us at any time, using the contact details provided in this document.'
            },
            {
                description:
                    'We use any personal data collected from you in connection with the submission of your opt-out request solely for the purposes of complying with the opt-out request.'
            }
        ]
    },{
        title: 'Your privacy rights under the Virginia Consumer Data Protection Act and how to exercise them',
        info: [
            {
                description:
                    'You may exercise certain rights regarding your data processed by us. In particular, you have the right to do the following:'
            },
            {
                description:
                    '• access personal data: the right to know. You have the right to request that we confirm whether or not we are processing your personal data. You also have the right to access such personal data.'
            },
            {
                description:
                    '• correct inaccurate personal data. You have the right to request that we correct any inaccurate personal data we maintain about you, taking into account the nature of the personal data and the purposes of the processing of the personal data.'
            },
            {
                description:
                    '• request the deletion of your personal data. You have the right to request that we delete any of your personal data.'
            },
            {
                description:
                    '• obtain a copy of your personal data. We will provide your personal data in a portable and usable format that allows you to transfer data easily to another entity — provided that this is technically feasible.'
            },
            {
                description:
                    '• opt out of the processing of your personal data for the purposes of targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects concerning you.'
            },
            {
                description:
                    '• non-discrimination. We will not discriminate against you for exercising your rights under the VCDPA. This means that we will not, among other things, deny goods or services, charge you a different price, or provide a different level or quality of goods or services just because you exercised your consumer privacy rights. However, if you refuse to provide your personal data to us or ask us to delete or stop selling your personal data, and that personal data or sale is necessary for us to provide you with goods or services, we may not be able to complete that transaction. To the extent permitted by the law, we may offer a different price, rate, level, quality, or selection of goods or services to you, including offering goods or services for no fee, if you have exercised your right to opt out, or our offer is related to your voluntary participation in a bona fide loyalty, rewards, premium features, discounts, or club card program.'
            }
        ]
    },{
        title: 'How to exercise your rights',
        info: [
            {
                description:
                    'To exercise the rights described above, you need to submit your request to us by contacting us via the contact details provided in this document.'
            },
            {
                description:
                    'For us to respond to your request, we need to know who you are.'
            },
            {
                description:
                    'We will not respond to any request if we are unable to verify your identity using commercially reasonable efforts and therefore confirm that the personal data in our possession actually relates to you. In such cases, we may request that you provide additional information which is reasonably necessary to authenticate you and your request.'
            },
            {
                description:
                    'Making a consumer request does not require you to create an account with us. However, we may require you to use your existing account. We will use any personal data collected from you in connection with your request solely for the purposes of authentication, without further disclosing the personal data, retaining it longer than necessary for purposes of authentication, or using it for unrelated purposes.'
            },
            {
                description:
                    'If you are an adult, you can make a request on behalf of a minor under your parental authority.'
            }
        ]
    },{
        title: 'How and when we are expected to handle your request',
        info: [
            {
                description:
                    'We will respond to your request without undue delay, but in all cases and at the latest within 45 days of its receipt. Should we need more time, we will explain to you the reasons why, and how much more time we need. In this regard, please note that we may take up to 90 days to fulfill your request.'
            },
            {
                description:
                    'Should we deny your request, we will explain to you the reasons behind our denial without undue delay, but in all cases and at the latest within 45 days of receipt of the request. It is your right to appeal such decision by submitting a request to us via the details provided in this document. Within 60 days of receipt of the appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If the appeal is denied you may contact the Attorney General to submit a complaint.'
            },
            {
                description:
                    'We do not charge a fee to respond to your request, for up to two requests per year. If your request is manifestly unfounded, excessive or repetitive, we may charge a reasonable fee or refuse to act on the request. In either case, we will communicate our choices and explain the reasons behind them.'
            }
        ]
    },{
        title: 'Definitions and legal references',
    },{
        title: 'Personal Data (or Data)',
        info: [
            {
                description:
                    'Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.'
            }
        ]
    },{
        title: 'Usage Data',
        info: [
            {
                description:
                    'Information collected automatically through Hoppysearch Cloud (or third-party services employed in Hoppysearch Cloud), which can include: the IP addresses or domain names of the computers utilized by the Users who use Hoppysearch Cloud, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server’s answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User’s IT environment.'
            }
        ]
    },{
        title: 'User',
        info: [
            {
                description:
                    'The individual using Hoppysearch Cloud who, unless otherwise specified, coincides with the Data Subject.'
            }
        ]
    },{
        title: 'Data Subject',
        info: [
            {
                description:
                    'The natural person to whom the Personal Data refers.'
            }
        ]
    },{
        title: 'Data Processor (or Data Supervisor)',
        info: [
            {
                description:
                    'The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.'
            }
        ]
    },{
        title: 'Data Controller (or Owner)',
        info: [
            {
                description:
                    'The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of Hoppysearch Cloud. The Data Controller, unless otherwise specified, is the Owner of Hoppysearch Cloud.'
            }
        ]
    },{
        title: 'Hoppysearch Cloud (or this Application)',
        info: [
            {
                description:
                    'The means by which the Personal Data of the User is collected and processed.'
            }
        ]
    },{
        title: 'Service',
        info: [
            {
                description:
                    'The service provided by Hoppysearch Cloud as described in the relative terms (if available) and on this site/application.'
            }
        ]
    },{
        title: 'European Union (or EU)',
        info: [
            {
                description:
                    'Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.'
            }
        ]
    },{
        title: 'Cookie',
        info: [
            {
                description:
                    'Cookies are Trackers consisting of small sets of data stored in the User’s browser.'
            }
        ]
    },{
        title: 'Tracker',
        info: [
            {
                description:
                    'Tracker indicates any technology – e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting – that enables the tracking of Users, for example by accessing or storing information on the User’s device.'
            }
        ]
    },{
        title: 'Legal information',
        info: [
            {
                description:
                    'This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).'
            },
            {
                description:
                    'This privacy policy relates solely to Hoppysearch Cloud, if not stated otherwise within this document.'
            }
        ]
    }
];
