import LAYOUT_CONST from 'constant';

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';

export const HORIZONTAL_MAX_ITEM = 6;

let baseAuthUrl = "https://auth.hoppysearch.com";
let clientId = "2icj4k0190ljd0d530nrg3h8d7";
let redirectUrl = "https://hoppysearch.com/auth";

export const AUTH_CONFIG = {
    baseAuthUrl,
    tokenUrl: baseAuthUrl + "/oauth2/token",
    infoUrl: baseAuthUrl + "/oauth2/userInfo",
    redirectUrl,
    clientId,
    loginPage: `${baseAuthUrl}/login?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${redirectUrl}`
}

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
