export const ADD_INDEX_DATA = 'ADD_INDEX_DATA';
export const GET_INDEX = 'GET_INDEX';
export const GET_INDEX_ID = 'GET_INDEX_ID';
export const GET_ALL_INDICES = 'GET_ALL_INDICES';
export const CREATE_API_KEY = 'CREATE_API_KEY';
export const GET_API_KEYS = 'GET_API_KEYS';
export const UDPATE_INDEX_METRICS = 'UDPATE_INDEX_METRICS';
export const UDPATE_METRICS_TIMEWINDOW = 'UDPATE_METRICS_TIMEWINDOW';
export const METRICS_LOADER = 'METRICS_LOADER';
export const GET_ALL_CORS = 'GET_ALL_CORS';
export const USER_INFO_STORE = 'USER_INFO_STORE';
export const CALL_TO_USER_INFO_PAGE = 'CALL_TO_USER_INFO_PAGE';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_BILLS = 'GET_USER_BILLS';
export const ADD_USER_BILL = 'ADD_USER_BILL';
export const API_FAILED_MESSAGE = 'API_FAILED_MESSAGE';
export const GET_INDEX_STATS = 'GET_INDEX_STATS';
export const INDEX_STATS_FAIL_ERROR_MESSAGE = "INDEX_STATS_FAIL_ERROR_MESSAGE"
export const METRICS_FAIL_ERROR_MESSAGE = "METRICS_FAIL_ERROR_MESSAGE"
export const INDICES_PAGE_LOADER = "INDICES_PAGE_LOADER"
export const GET_ADDRESS_SERVICE_INDEX = "GET_ADDRESS_SERVICE_INDEX"
export const GET_ADDRESS_SERVICE_READ_API_KEYS = "GET_ADDRESS_SERVICE_READ_API_KEYS"