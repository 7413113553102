import { UDPATE_INDEX_METRICS, UDPATE_METRICS_TIMEWINDOW, METRICS_LOADER, METRICS_FAIL_ERROR_MESSAGE} from '../api/constant';

const metrics = (state = { metrics: [], metricsTimeWindow: {}, metricsLoader: true, metricsFailErrorMessage: false }, action) => {
    switch (action.type) {
        case UDPATE_INDEX_METRICS:
            let metrics = action.data?.metrics?.MetricDataResults;
            return { ...state, metrics: metrics };
        case UDPATE_METRICS_TIMEWINDOW:
            return { ...state, metricsTimeWindow: action.payload };
        case METRICS_LOADER:
            return { ...state, metricsLoader: action.payload };
        case METRICS_FAIL_ERROR_MESSAGE:
            return { ...state, metricsFailErrorMessage: action.payload };
        default:
            return state;
    }
};

export default metrics;
