import { USER_INFO_STORE, CALL_TO_USER_INFO_PAGE,GET_USER_INFO } from '../api/constant';

const userInfo = (state = { userInfo: null, userInfoPageCall: null, getUserInfo: null }, action) => {
    switch (action.type) {
        case USER_INFO_STORE:
            return { ...state, userInfo: action.data };
        case CALL_TO_USER_INFO_PAGE:
            return { ...state, userInfoPageCall: action.payload };
        case GET_USER_INFO:
            return { ...state, getUserInfo: action.data };
        default:
            return state;
    }
};
export default userInfo;
