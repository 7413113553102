import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import TwoWheelerTwoToneIcon from '@mui/icons-material/TwoWheelerTwoTone';
import AirportShuttleTwoToneIcon from '@mui/icons-material/AirportShuttleTwoTone';
import DirectionsBoatTwoToneIcon from '@mui/icons-material/DirectionsBoatTwoTone';

const plans = [
    {
        active: false,
        icon: <TwoWheelerTwoToneIcon fontSize="large" color="inherit" />,
        title: 'Standard',
        description:
            '',
        price: 0.3,
        price2: 0.2,
        price3: 0.15,
        permission: [0, 1, 2, 3]
    },
    {
        active: false,
        icon: <DirectionsBoatTwoToneIcon fontSize="large" />,
        title: 'Plus',
        description:
            '',
        price: 0.7,
        price2: 0.4,
        price3: 0.2,
        permission: [0, 1, 2, 3, 4, 5, 6, 7, 8]
    }
];

const planList = [
    'First 10,000 requests Free',
    'Search API', // 0
    'Search Analytics', // 1
    'Auto Complete', // 2
    'Synonyms', // 3
    'Management API', // 4
    'Dynamic Re-Ranking', // 5
    'Personalized Ranking', // 6
    'Multi Region Replication' // 7
];

// ===============================|| PRICING - PRICE 1 ||=============================== //

const Price1 = () => {
    const theme = useTheme();
    const priceListDisable = {
        opacity: '0.4',
        '& >div> svg': {
            fill: theme.palette.secondary.light
        }
    };
    return (
        <Grid container spacing={gridSpacing}>
            {plans.map((plan, index) => {
                const darkBorder = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75;
                return (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                        <MainCard
                            boxShadow
                            sx={{
                                pt: 1.75,
                                border: plan.active ? '2px solid' : '1px solid',
                                borderColor: plan.active ? 'secondary.main' : darkBorder
                            }}
                        >
                            <Grid container textAlign="center" spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '50%',
                                            width: 80,
                                            height: 80,
                                            background:
                                                theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
                                            color: theme.palette.primary.main,
                                            '& > svg': {
                                                width: 35,
                                                height: 35
                                            }
                                        }}
                                    >
                                        {plan.icon}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontSize: '1.5625rem',
                                            fontWeight: 500,
                                            position: 'relative',
                                            mb: 1.875,
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                bottom: -15,
                                                left: 'calc(50% - 25px)',
                                                width: 50,
                                                height: 4,
                                                background: theme.palette.primary.main,
                                                borderRadius: '3px'
                                            }
                                        }}
                                    >
                                        {plan.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{plan.description}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        component="div"
                                        variant="body2"
                                        sx={{
                                            fontSize: '2.1875rem',
                                            fontWeight: 700,
                                            '& > span': {
                                                fontSize: '1.25rem',
                                                fontWeight: 500
                                            }
                                        }}
                                    >
                                        <sup>$</sup>
                                        {plan.price}
                                        <span>/1000 requests</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        component="div"
                                        variant="body2">
                                        Next 50,000 requests at ${plan.price}/1000 requests
                                    </Typography>
                                    <Typography
                                        component="div"
                                        variant="body2">
                                        Next 100,000 requests at ${plan.price2}/1000 requests
                                    </Typography>
                                    <Typography
                                        component="div"
                                        variant="body2">
                                        Beyond 160,000 requests at ${plan.price3}/1000 requests
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <List
                                        sx={{
                                            m: 0,
                                            p: 0,
                                            '&> li': {
                                                px: 0,
                                                py: 0.625,
                                                '& svg': {
                                                    fill: theme.palette.success.dark
                                                }
                                            }
                                        }}
                                        component="ul"
                                    >
                                        {planList.map((list, i) => (
                                            <React.Fragment key={i}>
                                                <ListItem sx={!plan.permission.includes(i) ? priceListDisable : {}}>
                                                    <ListItemIcon>
                                                        <CheckTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={list} />
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        component={RouterLink}
                                        to="/dashboard"
                                    >
                                        Choose Plan
                                    </Button>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Grid>

                );
            })}
        </Grid>
    );
};

export default Price1;
